<template>
    <div class="sign-in container text-center">
        <div class="form-signin mt-4">
            <img class="mb-2" src="@/assets/logo.svg" alt="Logo" width="256" height="256">
            <h1 class="h3 mb-3 font-weight-normal">Admin Login</h1>
            <b-alert
                variant="danger"
                :show="errorMessage.length > 0"
            >
                <font-awesome-icon icon="exclamation-triangle" />
                {{  errorMessage }}
            </b-alert>
            <b-input-group>
                <b-input-group-prepend>
                    <b-input-group-text>
                        <font-awesome-icon icon="envelope" />
                    </b-input-group-text>
                </b-input-group-prepend>
                <b-input
                    name="email"
                    v-model="email"
                    placeholder="Email address"
                    :disabled="working"
                />
            </b-input-group>
            <b-input-group class="mt-2">
                <b-input-group-prepend>
                    <b-input-group-text>
                        <font-awesome-icon icon="key" />
                    </b-input-group-text>
                </b-input-group-prepend>
                <b-input
                    name="password" 
                    type="password"
                    v-model="password"
                    placeholder="Password"
                    size="lg"
                    :disabled="working"
                />
            </b-input-group>
            <div class="checkbox my-3">
                <label>
                  <input type="checkbox" value="remember-me" v-model="remember" :disabled="working"> Remember me
                </label>
            </div>
            <button
                class="sc-btn sc-btn-lg sc-btn-block pill"
                @click="login"
                type="submit"
                :disabled="working"
            >
                <b-spinner v-if="working" small class="mr-2" />
                Sign in
            </button>
            <p class="mt-5 mb-3 text-muted">&copy; 2024 Daniel Mitchell</p>
        </div>
    </div>
</template>
<script>
import { api } from '@/api/client';
import { authenticate } from '@/api/authenticate';
export default {
    data () {
        return {
            errorMessage: "",
            email: "",
            password: "",
            remember: true,
            working: false
        };
    },
    methods: {
        async login () {
            this.working = true;
            localStorage.setItem("remember", true);
            const { data } = await authenticate({
                email: this.email,
                password: this.password,
                remember: this.remember
            });
            if (data.authenticated) {
                // Get home interface
                const { data } = await api.get("/api/admin/home");
                if (data.fetched) {
                    this.$store.commit("setInterface", data);
                }
                this.$bvToast.toast("You are logged in.", {
                    title: "Logged in",
                    autoHideDelay: 5000,
                    appendToast: true
                });
                this.pushRedirect(data.redirect);
            } else {
                this.errorMessage = data.error;

                // Show error alert.
                this.$bvToast.toast(data.error, {
                    title:   (data.authenticated) ? "Success" : "Error",
                    variant: (data.authenticated) ? "success" : "danger",
                    autoHideDelay: 5000,
                    appendToast: true
                });
            }
            this.working = false;
        },
        pushRedirect(redirect) {
            if (redirect) {
                this.$router.push(redirect);
            } else if (this.$router.currentRoute.query.redirect) {
                this.$router.push(this.$router.currentRoute.query.redirect);
            } else {
                this.$router.push({ name: "home" }); 
            } 
        }
    }
}
</script>
<style>

.sign-in {
  align-items: left;
  justify-content: left;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
</style>